import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {QuizService} from 'src/app/services/quiz.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {AnswerResponseModal} from 'src/app/types/content';
import {sleep} from 'src/app/utils/utils';

@Component({
  selector: 'app-answer-modal',
  templateUrl: './answer-modal.component.html',
  styleUrls: ['./answer-modal.component.scss'],
})
/**
* @export
 * @class AnswerModalComponent
 * @implements {OnInit}
 */
export class AnswerModalComponent implements OnInit, OnDestroy {
  public visible = false;
  public answerMessage: string;
  public genericContent: AnswerResponseModal;
  public score = 0;
  public bonusScore = 0;
  public totalScore = 0;
  public nextLabel: string;
  public title: string;
  public correct: boolean;
  private answerSub: Subscription;

  /**
   * Creates an instance of AnswerModalComponent.
   */
  constructor(public quizService:QuizService,
    private storyblokService:StoryblokService) {
    quizService.onStateUpdate.subscribe( () => this.hide.bind(this));
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.answerSub = this.quizService.onQuestionAnswered.subscribe(() => this.show());
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.answerSub?.unsubscribe();
  }

  /**
   *
   * @private
   */
  private show() {
    // app-quiz-container
    const quizContainer = document.getElementsByTagName('app-screens-container')[0];
    quizContainer?.classList.add('no-scroll');

    const answerCorrect = this.quizService.currentAnswerCorrect;
    this.correct = answerCorrect;
    const currentQuestion = this.quizService.currentQuestion;
    const question = this.quizService.activeQuiz.questions[currentQuestion];
    this.answerMessage = question.answer_message;
    this.genericContent = this.quizService.activeQuiz.answer_response_modal[0];
    this.score = this.correct ? this.quizService.pointsPerQuestion : 0;
    this.bonusScore = this.quizService.userTimeBonusScore;
    this.totalScore = this.quizService.userScore;
    const lastQuestion = this.quizService.currentQuestion === this.quizService.totalQuestions-1;
    this.nextLabel =
    lastQuestion ? this.genericContent.next_button_label_results : this.genericContent.next_button_label;

    this.title = this.correct ? this.genericContent.correct_title : this.genericContent.incorrect_title;
    this.visible = true;
  }

  /**
   */
  public onNextQuestion() {
    this.hide();
    this.quizService.nextQuestion();
  }

  /**
   * @private
   */
  private hide() {
    this.visible = false;
    const quizContainer = document.getElementsByTagName('app-screens-container')[0];
    quizContainer?.classList.remove('no-scroll');
  }
}
