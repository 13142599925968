import { Component, OnInit } from '@angular/core';

// Cute loader animation from https://loading.io/css/
@Component({
  selector: 'app-loader-animation',
  templateUrl: './loader-animation.component.html',
  styleUrls: ['./loader-animation.component.scss']
})
export class LoaderAnimationComponent {
}
