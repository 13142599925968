import {Component, OnInit} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {QuizService} from './services/quiz.service';
import {StoryblokService} from './services/storyblok.service';
import {sleep} from './utils/utils';
import {dynamicallyGenerateRoutes} from './app-routing.module';
import {filter} from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
/**
 * @export
 * @class AppComponent
 * @implements {OnInit}
 */
export class AppComponent implements OnInit {
  currentPath: any;
  showCookieModal: any;
  showCookiePage: any;
  /**
   * Creates an instance of AppComponent.
   * @param {StoryblokService} storyblokService
   * @param {QuizService} quizService
   */
  constructor(private storyblokService: StoryblokService,
    private analyticsService: AnalyticsService,
      public quizService: QuizService,
      private router: Router) {

        this.appendAnalytics();
        this.appendCookies();
        router.events
        .pipe(filter((event: any) => event instanceof ActivationEnd))
        .subscribe((e: ActivationEnd) => {
          this.currentPath = e.snapshot.url.length ?
                    e.snapshot.url[0].path : null;
          this.analyticsService.sendPageView();
        });
  }
    /**
     * append to index.html as js script
     */
    appendAnalytics() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      // eslint-disable-next-line max-len
      s.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaMeasurementId}`;
      document.body.append(s);
  
      const t = document.createElement('script');
      t.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.gaMeasurementId}');`;
      document.body.append(t);
    }
  
    /**
       * append to index.html as js script
       */
    appendCookies() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      s.setAttribute('data-domain-script', environment.cookiesId);
      document.body.append(s);
  
      const t = document.createElement('script');
      t.innerHTML = 'function OptanonWrapper() { }';
      document.body.append(t);
    }

  /**
   * @return {*}  {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    try {
      await this.storyblokService.loadQuiz();
      // await sleep(1000);
      dynamicallyGenerateRoutes(this.router, this.storyblokService.content);
      this.router.navigate(['welcome']);
    } catch (error) {
      console.warn(error);
    }
  }
}
