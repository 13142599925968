import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {QuizService} from 'src/app/services/quiz.service';
import {Quiz, Results, ResultsButton, ScoreMessages} from 'src/app/types/content';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
/**
 *
 * @export
 * @class ResultsComponent
 */
export class ResultsComponent {
  public content: Results;
  public scoreMessage: ScoreMessages;
  public titleMessage: string;

  /**
   * Creates an instance of ResultsComponent.
   * @param {QuizService} quizService
   * @param {ActivatedRoute} activatedRoute
   */
  constructor(private quizService: QuizService,
    private activatedRoute: ActivatedRoute) {
    const content: Quiz = activatedRoute.snapshot.data.content;
    // this is always [0] due to the way storyblok is setup
    this.content = content.results[0];
    const totalScore = quizService.userScore;

    this.scoreMessage = this.content.score_messages[0];
    // find users rank based on score
    for (let i = 0; i < this.content.score_messages.length; i++) {
      // "score_range": "60-100",
      const scoreRange = this.content.score_messages[i].score_range.split('-');
      const scoreRangeMin = parseInt(scoreRange[0]);
      if (totalScore >= scoreRangeMin) {
        this.scoreMessage = this.content.score_messages[i];
      }
    }
    const score = quizService.userScore;
    const totalPoints = quizService.totalPossibleScore;
    this.titleMessage = this.scoreMessage.title_message.replace('${score}', score.toString());
    this.titleMessage = this.titleMessage.replace('${total_points}', totalPoints.toString());
  }

  /**
   *
   * @param {ResultsButton} button
   */
  public onButtonClick(button: ResultsButton) {
    if (button.type === 'link') {
      window.open(button.link, '_blank');
    } else if (button.type === 'replay') {
      this.quizService.replay();
    } else if (button.type === 'play_next') {
      this.quizService.startQuizId(button.link);
    }
  }

  /**
   *
   * @param {ResultsButton} button
   * @return {*}  {string}
   */
  public getButtonIcon(button: ResultsButton): string {
    if (button.type === 'link') {
      return './assets/img/next-arrow.svg';
    } else if (button.type === 'replay') {
      return './assets/img/replay.svg';
    } else if (button.type === 'play_next') {
      return './assets/img/next-arrow.svg';
    }
  }
}
