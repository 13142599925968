
<ng-container *ngIf="visible">
    <div class="background"></div>

    <div class="modal-container">
        <div class="modal" [ngClass]="{'incorrect': !correct}">
            <div>
                <div class="title">{{title}}</div>
                <br>
                <div class="body">{{answerMessage}}</div>
            </div>
    
            <div class="score">
                <div class="row">
                    <div>{{genericContent.score_label_correct}}</div>
                    <div>+{{score}} pts</div>
                </div>
                <div class="row">
                    <div>{{genericContent.score_label_bonus}}</div>
                    <div>+{{bonusScore}} pts</div>
                </div>
                <hr>
                <div class="row">
                    <div>{{genericContent.score_label_current_score}}</div>
                    <div>{{totalScore}}</div>
                </div>
            </div>
    
            <div class="btn" (click)="onNextQuestion()">
                {{nextLabel}} <img src="./assets/img/next-arrow.svg">
            </div>
        </div>
    </div>

</ng-container>
