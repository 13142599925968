import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouterModule, Routes} from '@angular/router';
import {LoadingComponent} from './pages/loading/loading.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import {ContentLoaded} from './services/content-loaded.guard';
import {QuizContent} from './types/content';
import {QuizContainerComponent} from './pages/quiz-container/quiz-container.component';
import {QuizComponent} from './pages/quiz/quiz.component';
import {nameToPath} from './utils/utils';
import {ResultsComponent} from './pages/results/results.component';

const routes: Routes = [
  {
    path: '',
    component: LoadingComponent,
    data: {screenPosition: {x: 0, y: 0}},
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [ContentLoaded],
    data: {screenPosition: {x: 1, y: 0}},
  },
  // IMPORTANT NOTE
  // the following routing object structure gets generated dynamically
  // based on content from Storyblok by the method:
  // dynamicallyGenerateRoutes.
  //
  // Uncomment the below to test without Storyblok dynamic routing
  //   (your milage might vary)
  //
  // {
  //   path: 'quiz/:quizname',
  //   component: QuizContainerComponent,
  //   canActivate: [ContentLoaded],
  //   data: {screenPosition: {x: 2, y: 0}},
  //   children: [
  //     {
  //       path: '0',
  //       component: QuizComponent,
  //       data: {screenPosition: {x: 1, y: 0},
  //       },
  //     },
  //     {
  //       path: '1',
  //       component: QuizComponent,
  //       data: {screenPosition: {x: 2, y: 0},
  //       },
  //     },
  //     {
  //       path: '2',
  //       component: QuizComponent,
  //       data: {screenPosition: {x: 3, y: 0},
  //       },
  //     },
  //     {
  //       path: '3',
  //       component: QuizComponent,
  //       data: {screenPosition: {x: 4, y: 0},
  //       },
  //     },
  //   ],
  // },
];

/**
 * @param {Router} router
 * @param {QuizContent} content
 */
export const dynamicallyGenerateRoutes = (router:Router, content:QuizContent) => {
  for (let i = 0; i < content.quizzes.length; i++) {
    // for each quiz object add a quiz route
    // eg:    quiz/${quizname}
    const quizContent = content.quizzes[i];
    const quizPath = nameToPath(quizContent.quiz_name);
    const quizRouteConfig = {
      path: `quiz/${quizPath}`,
      component: QuizContainerComponent,
      canActivate: [ContentLoaded],
      data: {
        content: quizContent,
        screenPosition: {x: 2, y: 0},
      },
      children: [],
    };

    // for each question add a child subroute
    // eg:    quiz/${quizname}/question/${0-n}
    for (let j = 0; j < quizContent.questions?.length; j++) {
      const question = quizContent.questions[j];
      const questionRouteConfig = {
        path: `question/${j}`,
        component: QuizComponent,
        data: {
          content: question,
          screenPosition: {x: j, y: 0},
        },
      };
      quizRouteConfig.children.push(questionRouteConfig);
    }
    router.config.push(quizRouteConfig);

    // add the results component
    // eg:    quiz/${quizname}/results
    const quizRouteResultsConfig = {
      path: `quiz/${quizPath}/results`,
      component: ResultsComponent,
      canActivate: [ContentLoaded],
      data: {
        content: quizContent,
        screenPosition: {x: 3, y: 0},
      },
    };
    router.config.push(quizRouteResultsConfig);
  }

  console.log(router.config);
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {useHash: true}),
  ],
  exports: [RouterModule],
})

/**
 *
 * @export
 * @class AppRoutingModule
 */
export class AppRoutingModule { }
