import {Component, ElementRef, HostBinding, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {QuizService} from 'src/app/services/quiz.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
/**
 * @export
 * @class StepperComponent
 */
export class StepperComponent implements OnInit {
  private currentStep = 0;
  private totalSteps;
  private stepperBar: HTMLDivElement;
  private barWidth: number;

  /**
   * Creates an instance of StepperComponent.
   */
  constructor(private quizService: QuizService,
    private element: ElementRef) {
    this.updateState();

    quizService.onStateUpdate.subscribe( () => {
      this.updateState();
    });
  }

  /**
   *
   */
  ngOnInit(): void {
    if (!this.stepperBar) {
      this.stepperBar = document.getElementById('step-bar') as HTMLDivElement;
    }
    this.barWidth = this.stepperBar.getBoundingClientRect().width + 10;
  }

  /**
   * Update state based on the current URL
   *
   * @param {string} url
   */
  private updateState() {
    this.totalSteps = this.quizService.totalQuestions;
    this.currentStep = this.quizService.currentQuestion;
  }

  /**
   * @return {*}  {string}
   */
  public getPreviousLabel(): string {
    return `0${this.currentStep + 1}`;
  }

  /**
   * @public
   * @return {*}  {string}
   */
  public getNextLabel(): string {
    return `0${this.totalSteps}`;
  }

  /**
   * @public
   * @return {*}  {number}
   */
  public indicatorBarX(): number {
    return (this.currentStep/this.totalSteps)*(this.barWidth-this.indicatorBarWidth());
  };

  /**
   * @public
   * @return {*}  {number}
   */
  public indicatorBarWidth(): number {
    return this.barWidth / this.totalSteps;
  }
}
