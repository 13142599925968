import {Component, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {Quiz} from 'src/app/types/content';
import {nameToPath} from 'src/app/utils/utils';
import {QuizService} from '../../services/quiz.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
/**
 * @export
 * @class WelcomeComponent
 * @implements {OnInit}
 */
export class WelcomeComponent {
  /**
   *
   * @param {ElementRef} el
   * @param {StoryblokService} storyblokService
   * @param {QuizService} quizService
   */
  constructor(private el: ElementRef,
    public storyblokService: StoryblokService,
    public router: Router,
    private analyticsService: AnalyticsService,
    private quizService: QuizService) {
  }

  /**
   *
   * @param {Quiz} quiz
   */
  public async onStartQuiz(quiz: Quiz) {
    this.quizService.startQuiz(quiz);
    this.analyticsService.sendEvent('Button Click', 'Start Quiz', quiz.take_quiz_btn_label);
  }
}
