<div class="number previous">
    {{getPreviousLabel()}}
</div>
<div class="bars">
    <div class="bar-bg" id="step-bar">
    </div>
    <div class="bar-overlay"
        [style.width.px]="indicatorBarWidth()"
        [style.transform]="'translateX(' + indicatorBarX() + 'px)'">
    </div>
</div>
<div class="number next">
    {{getNextLabel()}}
</div>

