<img class="hero-img" [src]="question.image.filename">

<div class="question-frame">
    <div class="question-text" [innerHTML]="question.question | safe: 'html'"></div>
    <div class="timer">
        <app-stopwatch></app-stopwatch>
    </div>
</div>

<div class="answer-choices">
    <ng-container *ngFor="let answer of question.answers;">
        <div (click)="onAnswerSelected(answer)" class="btn">{{answer.label}}</div>
    </ng-container>
</div>

<div class="footer">
    {{question.disclaimer}}
</div>