<div class="title" [innerHTML]="question.title | safe: 'html'"></div>

<div class="video">
    <video 
        #video
        [poster]="question.poster_image.filename"
        [src]="question.video.filename"
        playsInline></video>
    <img 
        [ngClass]="{'hidden':videoPlaying}"
        (click)="onPlayVideo()" 
        class='video-play-btn' 
        src="./assets/img/video-play-btn.svg">
</div>

<div class="question-frame">
    <div class="question-text" [innerHTML]="question.question | safe: 'html'"></div>
    <div class="timer">
        <app-stopwatch></app-stopwatch>
    </div>
</div>

<div class="answer-choices">
    <ng-container *ngFor="let answer of question.answers;">
        <div
            (click)="onAnswerSelected(answer)"
            class="btn"
            [ngClass]="{'disabled':!answersEnabled}">{{answer.label}}</div>
    </ng-container>
</div>

<div class="footer">
    {{question.disclaimer}}
</div>
