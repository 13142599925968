import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appScaleuniform]',
})
/**
 * Directive for uniformly scaling a div and it's contents
 * to fit a fixed dimension. Analogous to the css property
 *    object-fit: scale-down
 */
export class ScaleuniformDirective implements AfterViewInit {
  private readonly defaultWidth = 390;
  private readonly defaultHeight = 844;
  /**
   * Creates an instance of ScaleuniformDirective.
   * @param {Renderer2} renderer
   * @param {ElementRef} el
   */
  constructor(private renderer:Renderer2, private el: ElementRef) {
    this.initContainerStyles();
  }

  /**
   *
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeToFit();
  }

  /**
   *
   */
  ngAfterViewInit() {
    this.resizeToFit();
  }

  /**
   *
   */
  private initContainerStyles(): void {
    const htmlElement = this.el.nativeElement;
    htmlElement.style.width = `${this.defaultWidth}px`;
    htmlElement.style.transformOrigin = 'center top';
  }

  /**
   *
   */
  private resizeToFit(): void {
    const htmlElement = this.el.nativeElement;
    const scaleX = window.innerWidth/this.defaultWidth;
    const scaleY = window.innerHeight/this.defaultHeight;
    const scale = Math.min(scaleX, scaleY);
    htmlElement.style.transform = `scale(${scale}, ${scale})`;
  }
}
