import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { gsap } from "gsap";
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @ViewChild('btn') graphic: ElementRef;
  selected: boolean;
  submitted: boolean;
  faded: boolean;
  correct: boolean;
  disabled: boolean = false;
  constructor(public el:ElementRef) { }

  ngOnInit(): void {
  }


}

