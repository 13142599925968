import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {QuizService} from 'src/app/services/quiz.service';
import {TimerService} from 'src/app/services/timer.service';
import {Answer, VideoQuestion} from 'src/app/types/content';
import {sleep} from 'src/app/utils/utils';

@Component({
  selector: 'app-video-choice-quiz',
  templateUrl: './video-choice-quiz.component.html',
  styleUrls: ['./video-choice-quiz.component.scss', '../../quiz.component.scss'],
})
/**
 * @export
 * @class VideoChoiceQuizComponent
 * @implements {OnInit}
 */
export class VideoChoiceQuizComponent implements AfterViewInit {
  @ViewChild('video') videoElem: ElementRef;
  private video: HTMLVideoElement;
  public question: VideoQuestion;
  public videoPlaying: boolean;
  public videoCompleted: boolean;
  public answersEnabled: boolean;

  /**
   * Creates an instance of VideoChoiceQuizComponent.
   * @param {ActivatedRoute} activatedRoute
   * @param {QuizService} quizService
   */
  constructor(activatedRoute: ActivatedRoute,
    private timerService: TimerService,
    private analyticsService: AnalyticsService,
    private quizService: QuizService) {
    this.question = activatedRoute.snapshot.data.content;
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.timerService.stop();
    this.video = this.videoElem.nativeElement;
    this.video.addEventListener('ended', this.onVideoEnded.bind(this), true);
  }

  /**
   */
  public async onPlayVideo() {
    this.video.play();
    this.videoPlaying = true;
    await sleep(1000);
    this.answersEnabled = true;
    this.analyticsService.sendEvent('Video', 'Video Played', this.question.title);
  }


  /**
   */
  public onVideoEnded() {
    this.timerService.start();
    this.videoCompleted = true;
  }

  /**
   * @param {Answer} answer
   */
  public onAnswerSelected(answer: Answer) {
    this.quizService.answer(answer);
    this.analyticsService.sendEvent('Quiz Answer', 'Answer Selected', answer.label);
  }
}
