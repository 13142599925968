<div #btn 
[ngClass]="{
    'faded': submitted && !selected && !correct}"

>
<div class="button"
     [ngClass]="{
        'selected': selected,
        'correct': submitted && correct,
        'incorrect': submitted && selected && !correct,
        'animate': submitted && (selected && correct)
      }"
      >
  <div class="label">
    <ng-content></ng-content>
  </div>
</div>

</div>