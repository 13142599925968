import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {
  private toggle: boolean;
  private toggleOpacity: boolean;
  constructor(private el: ElementRef) { }

  currentImage: number = 1;
  images: string[];
  imgSrc: string;
  imgPath: string = 'assets/guides/';

  ngOnInit(): void {
    this.images = [
      // 'Welcome.png',
      'Welcome-Content.png',
      // 'Question.png',
      'Question-content.png',
    ]
    const src = this.images[this.currentImage];
    this.imgSrc = `${this.imgPath}/${src}`;
    this.el.nativeElement.style.opacity = 0;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandlerOne(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.toggle = !this.toggle;
      this.el.nativeElement.style.zIndex = this.toggle ? 100 : -100;
    }
    if (event.key === '1') {
      this.toggleOpacity = !this.toggleOpacity;
      this.el.nativeElement.style.opacity = this.toggleOpacity ? 1 : .0;
    }
    if (event.key === 'Space') {
      const src = this.images[++this.currentImage % this.images.length];
      this.imgSrc = `${this.imgPath}/${src}`;
    }
  }

}
