import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Answer, ImageAnswer, ImageGridQuestion, Question, Quiz, VideoQuestion} from '../types/content';
import {nameToPath, sleep} from '../utils/utils';
import {StoryblokService} from './storyblok.service';
import {TimerService} from './timer.service';


@Injectable({
  providedIn: 'root',
})
/**
 * @export
 * @class QuizService
 */
export class QuizService {
  public onStateUpdate: EventEmitter<void> = new EventEmitter();
  public onQuestionAnswered: EventEmitter<void> = new EventEmitter();
  public currentQuestion = 0;
  public totalQuestions: number;
  public activeQuiz: Quiz;

  public pointsPerQuestion = 10;
  public timeBonusPerQuestion = 10;
  public totalPossibleScore;
  public userScore;
  public userTimeBonusScore;
  public currentAnswerCorrect: boolean;

  /**
   * Creates an instance of QuizService.
   * @param {Router} router
   */
  constructor(private router:Router,
    private timerService: TimerService,
    private storyblokService: StoryblokService) {
    timerService.complete.subscribe(
        (finished:boolean) => {
          if (finished) this.onTimerUp();
        });
    window.addEventListener('popstate', this.onPopState.bind(this));
  }

  /**
   * @param {Quiz} quiz
   */
  public startQuiz(quiz: Quiz) {
    this.activeQuiz = quiz;
    const quizRoute = `quiz/${nameToPath(this.activeQuiz.quiz_name)}`;
    const currentRouteConfig = this.router.config.find( (r) => r.path === quizRoute);

    // scoring
    this.totalQuestions = currentRouteConfig.children.length;
    this.totalPossibleScore = this.totalQuestions * (this.pointsPerQuestion + this.timeBonusPerQuestion);
    this.userScore = 0;
    this.userTimeBonusScore = 0;

    this.gotoQuestion(this.activeQuiz, 0);
    this.onStateUpdate.emit();
  }

  /**
   * @param {Answer} answer
   */
  public answer(answer: Answer | ImageAnswer | null) {
    this.currentAnswerCorrect = answer?.correct === 'true';
    const questionAnswerTimeBonus = (1 - this.timerService.timeElapsedPercent) * this.timeBonusPerQuestion;

    this.userTimeBonusScore = this.currentAnswerCorrect ? Math.round(questionAnswerTimeBonus) : 0;
    this.userScore += this.currentAnswerCorrect ? this.pointsPerQuestion : 0;
    this.userScore += this.userTimeBonusScore;
    this.userScore = Math.min(this.totalPossibleScore, Math.round(this.userScore));
    this.timerService.stop();
    this.onQuestionAnswered.emit();
  }

  /**
   *
   *
   */
  public nextQuestion() {
    const quizRoute = `quiz/${nameToPath(this.activeQuiz.quiz_name)}`;
    const currentRouteConfig = this.router.config.find( (r) => r.path === quizRoute);
    if (this.currentQuestion + 1 < currentRouteConfig.children.length) {
      this.currentQuestion++;
      this.gotoQuestion(this.activeQuiz, this.currentQuestion);
    } else {
      console.log('QUIZ COMPLETE');
      const path = `${quizRoute}/results`;
      this.navigate(path);
    }
    this.onStateUpdate.emit();
  }

  /**
   */
  private onPopState() {
    const routeComponents = window.location.hash.split('/');
    const questionNumber = parseInt(routeComponents[routeComponents.length-1]);
    if (!isNaN(questionNumber)) {
      this.gotoQuestion(this.activeQuiz, questionNumber);
    } else if (routeComponents[routeComponents.length-1] === 'welcome') {
      this.navigate('welcome');
    }
    this.onStateUpdate.emit();
  }

  /**
   * @param {string} quizName
   */
  public startQuizId(quizName: string) {
    console.log('QuizService startQuizId', quizName);
    const quiz =
      this.storyblokService.content.quizzes.find( (q) => q.quiz_name.toLowerCase() === quizName.toLowerCase());
    if (quiz) {
      this.startQuiz(quiz);
    }
  }

  /**
  */
  public replay() {
    this.startQuiz(this.activeQuiz);
  }

  /**
   * @private
   * @param {Quiz} quiz
   * @param {number} question
   */
  private async gotoQuestion(quiz:Quiz, question:number) {
    const quizRoute = `quiz/${nameToPath(quiz.quiz_name)}`;
    this.currentQuestion = question;
    const path = `${quizRoute}/question/${question}`;
    this.navigate(path);
    this.timerService.reset();
  }

  /**
   *
   * @private
   */
  private onTimerUp(): void {
    this.answer(null);
  }

  /**
   *
   * @private
   * @param {string} path
   */
  private navigate(path: string) {
    this.router.navigate([path]);
    window.scrollTo(0, 0 );
  }
}
