import {Component} from '@angular/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss'],
})
/**
 *
 * @export
 * @class ThanksComponent
 * @implements {OnInit}
 */
export class ThanksComponent {

}
