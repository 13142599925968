import {Component, OnDestroy, OnInit} from '@angular/core';
import {TimerService} from 'src/app/services/timer.service';
import {gsap, Cubic} from 'gsap';
import {QuizService} from 'src/app/services/quiz.service';

@Component({
  selector: 'app-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.scss'],
})
/**
 * @export
 * @class StopwatchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class StopwatchComponent implements OnInit, OnDestroy {
  public complete = false;
  public timerArcPath: string;
  public fillColor = '#066FEF';
  private animating: boolean;

  /**
   * Creates an instance of StopwatchComponent.
   * @param {TimerService} timerService
   * @param {QuizService} quizService
   */
  constructor(private timerService: TimerService,
    public quizService: QuizService) {
    this.timerService.complete.subscribe((state) => {
      this.complete = state;
      if (this.complete) {
        this.flashTimer();
      } else {
        this.stopFlash();
      }
    });
  }

  /**
   */
  ngOnDestroy(): void {
    this.animating = false;
  }

  /**
   */
  ngOnInit(): void {
    this.animating = true;
    this.setArc = this.setArc.bind(this);
    requestAnimationFrame(this.setArc);
  }

  /**
   *
   */
  stopFlash() {
    const timerComplete = document.getElementById('timerComplete');
    gsap.killTweensOf(timerComplete);
    // gsap.set(timerComplete, { opacity: 0 });
  }

  /**
   *
   */
  flashTimer() {
    this.stopFlash();
    const timerComplete = document.getElementById('timerComplete');
    gsap.set(timerComplete, {opacity: 1});
    this.fillColor = '#fff';
    gsap.to(this, {
      duration: .2,
      fillColor: '#ff0000',
      repeat: 5,
      ease: Cubic.easeInOut,
    });
  }

  /**
   *
   * @private
   */
  private setArc() {
    const angle = this.timerService.timeElapsedPercent === 0 ? 359.99 : 360 - (this.timerService.timeElapsedPercent * 359);

    this.timerArcPath = this.describeArc(26, 26, 24.5, 0, angle);
    if (this.animating) {
      requestAnimationFrame(this.setArc);
    }
  }

  /**
   *
   * @private
   * @param {number} centerX
   * @param {number} centerY
   * @param {number} radius
   * @param {number} angleInDegrees
   * @return {*}
   */
  private polarToCartesian(centerX:number, centerY:number, radius:number, angleInDegrees:number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians)),
    };
  }

  /**
   * @private
   * @param {number} x
   * @param {number} y
   * @param {number} radius
   * @param {number} startAngle
   * @param {number} endAngle
   * @return {*}
   */
  private describeArc(x:number, y:number, radius:number, startAngle:number, endAngle:number) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
    ].join(' ');
    return d;
  }
}
