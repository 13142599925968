import {Component} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
/**
 * @export
 * @class HeaderComponent
 */
export class HeaderComponent {
  /**
   * Creates an instance of HeaderComponent.
   */
  constructor() {}
}
