import {
  animate,
  query,
  style,
  transition,
  trigger,
  group,
} from '@angular/animations';

const animation = '1100ms ease';

/**
 * Angular route transitions kind of suck, this is based on:
 * https://medium.com/@gogakoreli/angular-7-improved-router-transition-animation-5bc4578a2f3a
 * https://github.com/gogakoreli/angular-page-change-animation
 * @export
 * @return {*}
 */
export function routerAnimation() {
  return trigger('routerAnimation', [
    // One time initial load. Move page from left -110vw to 0%
    transition('-1 => *', [
      query(':enter', [
        style({
          transform: 'translateX(110vw)',
        }),
        animate(
            animation,
            style({
              opacity: 1,
              transform: 'translateX(0%)',
            }),
        ),
      ]),
    ]),

    // Previous, slide left to right to show left page
    transition(':decrement', [
      // set new page X location to be -110vw
      query(
          ':enter',
          style({
            transform: 'translateX(-110vw)',
          }),
      ),

      group([
        // slide existing page from 0% to 110vw to the right
        query(
            ':leave',
            animate(
                animation,
                style({
                  transform: 'translateX(110vw)',
                }),
            ),
        ),
        // slide new page from -110vw to 0% to the right
        query(
            ':enter',
            animate(
                animation,
                style({
                  opacity: 1,
                  transform: 'translateX(0%)',
                }),
            ),
        ),

        // eslint-disable-next-line max-len
        // from https://stackoverflow.com/questions/62700946/angular-nested-router-outlet-children-disappear-before-router-animation-complete
        // force the inner content to be animated
        // with a real but not visible animation
        // the inner content is not removed until the animation ends
        // we use the same 200ms
        query(':leave *', [
          style({}),
          animate(1, style({})),
        ], {optional: true}),
      ]),
    ]),

    // Next, slide right to left to show right page
    transition(':increment', [
      // set new page X location to be 110vw
      query(
          ':enter',
          style({
            transform: 'translateX(110vw)',
          }),
      ),

      group([
        // slide existing page from 0% to -110vw to the left
        query(
            ':leave',
            animate(
                animation,
                style({
                  transform: 'translateX(-110vw)',
                }),
            ),
        ),
        // slide new page from 110vw to 0% to the left
        query(
            ':enter',
            animate(
                animation,
                style({
                  opacity: 1,
                  transform: 'translateX(0%)',
                }),
            ),
        ),

        // eslint-disable-next-line max-len
        // from https://stackoverflow.com/questions/62700946/angular-nested-router-outlet-children-disappear-before-router-animation-complete
        // force the inner content to be animated
        // with a real but not visible animation
        // the inner content is not removed until the animation ends
        // we use the same 200ms
        query(':leave *', [
          style({}),
          animate(1, style({})),
        ], {optional: true}),

      ]),
    ]),
  ]);
}

