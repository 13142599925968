import {CanActivate} from '@angular/router';
import {StoryblokService} from './storyblok.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * @export
 * @class ContentLoaded
 * @implements {CanActivate}
 */
export class ContentLoaded implements CanActivate {
  /**
     * Creates an instance of CanActivateTeam.
     * @param {StoryblokService} storyblokService
     */
  constructor(private storyblokService: StoryblokService) {}
  /**
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @return {*}  {Observable<boolean>}
     */
  canActivate(): boolean {
    return this.storyblokService.content != undefined;
  }
}
