/* eslint-disable @angular-eslint/no-host-metadata-property */
import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {routerAnimation} from './animations';

@Component({
  selector: 'app-screens-container',
  templateUrl: './screens-container.component.html',
  styleUrls: ['./screens-container.component.scss'],
  animations: [routerAnimation()],
})
/**
 * @export
 * @class ScreensContainerComponent
 */
export class ScreensContainerComponent {
  /**
   *
   * @param {RouterOutlet} outlet
   * @return {*}
   */
  public getRouteAnimation(outlet: RouterOutlet) {
    const res =
      outlet.activatedRouteData['screenPosition'] === undefined ?
        -1 :
        outlet.activatedRouteData['screenPosition'].x;
    return res;
  }
}

