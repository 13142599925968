import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {QuizContent} from '../types/content';

type ExperienceId = string;
@Injectable({
  providedIn: 'root',
})
/**
 * @export
 * @class StoryblokService
 */
export class StoryblokService {
    public content: QuizContent;

    /**
     *
     * @param {ExperienceId} experienceId
     * @return {*}  {Promise<QuizContent>}
     */
    async loadQuiz(): Promise<QuizContent> {
      const experienceId = this.getExperienceIdFromURL() || environment.defaultStoryName;
      this.content = await this.loadFromStoryblok(experienceId);
      return this.content;
    }

    /**
     * @private
     * @return {*}  {string}
     */
    private getExperienceIdFromURL(): string {
      const url = new URL(document.URL);
      const searchParams = new URLSearchParams(url.search.replace('?', ''));
      return searchParams.get('experienceId');
    }

    /**
     *
     * @private
     * @param {ExperienceId} experienceId
     * @return {*}  {Promise<QuizContent>}
     */
    private async loadFromStoryblok(experienceId: ExperienceId): Promise<QuizContent> {
      const url = new URL(`https://api-us.storyblok.com/v2/cdn/stories/${experienceId}`);
      url.searchParams.append('token', environment.storyblokAccessToken);
      url.searchParams.append('version', 'draft');

      const resp = await fetch(url.href);
      if (resp.ok) {
        const json = await resp.json();
        console.log(json);
        return this.deserializeContent(json);
      } else {
        const warningStyle = 'font-weight: bold; font-size: 20px;color: red;)';
        console.warn('%cProblem loading content from Storyblok', warningStyle);
        console.warn('%cAre you missing the Experience ID query param?', warningStyle);
      }
      return null;
    }

    /**
     *
     * @private
     * @param {*} storyblokJson
     * @return {*}  {QuizContent}
     */
    private deserializeContent(storyblokJson: any): QuizContent {
      return storyblokJson.story.content;
    }
}
