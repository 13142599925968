import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 *
 * @export
 * @class TimerService
 */
export class TimerService {
    public complete:EventEmitter<boolean> = new EventEmitter<boolean>();
    public DEFAULT_TOTAL_SECONDS = 10;
    public timeElapsedPercent = 0;

    private running = false;
    private startTime: number;
    private totalSeconds: number;
    private elapsedMilliSeconds = 0;

    /**
     */
    private update() {
      if (this.running) {
        this.setElapsedTime(Date.now() - this.startTime);
        this.checkElapsedTime();
        window.requestAnimationFrame(this.update.bind(this));
      }
    }

    /**
     * @param {number} [seconds=this.DEFAULT_TOTAL_SECONDS]
     */
    public start(seconds: number = this.DEFAULT_TOTAL_SECONDS) {
      this.totalSeconds = seconds;
      this.startTime = Date.now();
      this.running = true;
      this.update();
      this.complete.emit(false);
    }

    /**
     */
    public stop() {
      this.running = false;
    }

    /**
     */
    public reset() {
      this.totalSeconds = this.DEFAULT_TOTAL_SECONDS;
      this.timeElapsedPercent = 0;
    }

    /**
     *
     * @param {*} time
     */
    private setElapsedTime(time) {
      this.elapsedMilliSeconds = time;
      const elapsedSeconds = this.elapsedMilliSeconds / 1000;
      this.timeElapsedPercent = Math.min(elapsedSeconds / this.totalSeconds, 1);
    }

    /**
     */
    private checkElapsedTime() {
      const elapsedSeconds = this.elapsedMilliSeconds / 1000;
      if (elapsedSeconds >= this.totalSeconds) {
        this.complete.emit(true);
        this.stop();
      }
    }
}

