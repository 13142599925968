import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Question} from 'src/app/types/content';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
/**
 *
 * @export
 * @class QuizComponent
 */
export class QuizComponent {
  public question:Question;

  /**
   * Creates an instance of QuizComponent.
   * @param {ActivatedRoute} activatedRoute
   */
  constructor(public activatedRoute: ActivatedRoute) {
    this.question = activatedRoute.snapshot.data.content as Question;
  }
}
