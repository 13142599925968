import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {routerAnimation} from '../screens-container/animations';

@Component({
  selector: 'app-quiz-container',
  templateUrl: './quiz-container.component.html',
  styleUrls: ['./quiz-container.component.scss'],
  animations: [routerAnimation()],
})
/**
 * @export
 * @class QuizContainerComponent
 */
export class QuizContainerComponent {
  /**
   *
   * @param {RouterOutlet} outlet
   * @return {*}
   */
  public getRouteAnimation(outlet: RouterOutlet) {
    const res =
      outlet.activatedRouteData['screenPosition'] === undefined ?
        -1 :
        outlet.activatedRouteData['screenPosition'].x;
    return res;
  }
}

