<div class="title">
    {{storyblokService.content.welcome_title}}
</div>

<div class="buttons-frame">
    <ng-container *ngFor="let quiz of storyblokService.content.quizzes;">
        <div class="button-container" 
            (click)="onStartQuiz(quiz)"
            [style.background-image]="'url(' + quiz.take_quiz_btn_image.filename + ')'">
            <div class="overlay"></div>
            <div class="button">
                {{quiz.take_quiz_btn_label}}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 4L13.1227 10.1227L7 16.2453" stroke="white" stroke-width="1.74933"/>
                </svg>
            </div>
        </div>
    </ng-container>
</div>