/**
 * @param {number} timeout
 * @return {*}
 */
export const sleep = (timeout: number) => new Promise((resolve) => {
  setTimeout(resolve, (timeout));
});

/**
 * @param {string} name Convert string to url friend
 * string by replacing spacing with underscores and lowercasing
 *  @return {string}
 */
export const nameToPath = (name:string):string => {
  return name.toLocaleLowerCase().replace(' ', '_');
};
