<ng-container *ngIf="question.component === 'question_text_type'">
    <app-text-choice-quiz></app-text-choice-quiz>
</ng-container>

<ng-container *ngIf="question.component === 'question_video_type'">
    <app-video-choice-quiz></app-video-choice-quiz>
</ng-container>

<ng-container *ngIf="question.component === 'question_image_grid_type'">
    <app-image-choice-quiz></app-image-choice-quiz>
</ng-container>