import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {QuizService} from 'src/app/services/quiz.service';
import {TimerService} from 'src/app/services/timer.service';
import {ImageAnswer, ImageGridQuestion} from 'src/app/types/content';

@Component({
  selector: 'app-image-choice-quiz',
  templateUrl: './image-choice-quiz.component.html',
  styleUrls: ['./image-choice-quiz.component.scss', '../../quiz.component.scss'],
})
/**
 * @export
 * @class ImageChoiceQuizComponent
 * @implements {OnInit}
 */
export class ImageChoiceQuizComponent {
  public question:ImageGridQuestion;
  public selectedAnswer:ImageAnswer;

  /**
   * Creates an instance of ImageChoiceQuizComponent.
   * @param {ActivatedRoute} activatedRoute
   * @param {QuizService} quizService
   */
  constructor(activatedRoute: ActivatedRoute,
    private timerService: TimerService,
    private analyticsService: AnalyticsService,
    private quizService: QuizService) {
    this.question = activatedRoute.snapshot.data.content;
    this.timerService.start();
  }
x
  /**
   * @param {Answer} answer
   */
  public onAnswerSelected(answer: ImageAnswer) {
    this.selectedAnswer = answer;
    this.quizService.answer(this.selectedAnswer);
     this.analyticsService.sendEvent('Quiz Answer', 'Image Answer Selected', answer.label);
  }
}
