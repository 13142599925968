import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ButtonComponent} from './components/button/button.component';
import {GuideComponent} from './components/guide/guide.component';
import {HeaderComponent} from './components/header/header.component';
import {LoaderAnimationComponent} from './components/loader-animation/loader-animation.component';
import {MessageboxComponent} from './components/messagebox/messagebox.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {StopwatchComponent} from './components/stopwatch/stopwatch.component';
import {VideoComponent} from './components/video/video.component';
import {ScaleuniformDirective} from './directives/scaleuniform.directive';
import {LoadingComponent} from './pages/loading/loading.component';
import {QuizComponent} from './pages/quiz/quiz.component';
import {ScreensContainerComponent} from './pages/screens-container/screens-container.component';
import {ThanksComponent} from './pages/thanks/thanks.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import {QuizContainerComponent} from './pages/quiz-container/quiz-container.component';
import {TextChoiceQuizComponent} from './pages/quiz/types/text-choice-quiz/text-choice-quiz.component';
import {VideoChoiceQuizComponent} from './pages/quiz/types/video-choice-quiz/video-choice-quiz.component';
import {ImageChoiceQuizComponent} from './pages/quiz/types/image-choice-quiz/image-choice-quiz.component';
import {ResultsComponent} from './pages/results/results.component';
import {SafePipe} from './directives/safehtml.pipe';
import { AnswerModalComponent } from './pages/quiz/components/answer-modal/answer-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    QuizComponent,
    HeaderComponent,
    ButtonComponent,
    ThanksComponent,
    StopwatchComponent,
    GuideComponent,
    MessageboxComponent,
    LoaderAnimationComponent,
    VideoComponent,
    ScaleuniformDirective,
    ScreensContainerComponent,
    LoadingComponent,
    SpinnerComponent,
    StepperComponent,
    QuizContainerComponent,
    TextChoiceQuizComponent,
    VideoChoiceQuizComponent,
    ImageChoiceQuizComponent,
    ResultsComponent,
    SafePipe,
    AnswerModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
/**
 * @export
 * @class AppModule
 */
export class AppModule {
  /**
   * Creates an instance of AppModule.
   */
  constructor() {}
}
