<div class="header">
    {{content.title}} 
</div>

<div class="title" [innerHTML]="titleMessage | safe: 'html'"></div>

<!-- [style.background-image]="'url(' + quiz.take_quiz_btn_image.filename + ')'"> -->
<div class="results-img-container" 
    [style.background-image]="'url('+scoreMessage.image.filename">
    <div class="overlay"></div>
    <div class="label">
        <div>{{scoreMessage.rank}}</div>
        
        <div class="rank-stars-container">
            <img [src]="'./assets/img/'+ (scoreMessage.number_stars < 1 ? 'star-outline.svg' : 'star-filled.svg') ">
            <img [src]="'./assets/img/'+ (scoreMessage.number_stars < 2 ? 'star-outline.svg' : 'star-filled.svg') ">
            <img [src]="'./assets/img/'+ (scoreMessage.number_stars < 3 ? 'star-outline.svg' : 'star-filled.svg') ">
        </div>
    </div>
</div>

<div class="body"  [innerHTML]="scoreMessage.message | safe: 'html'"></div>

<div class="replay-btn-container">
    <ng-container *ngFor="let button of content.buttons;">
        <div class="btn" (click)="onButtonClick(button)">{{button.label}}<img [src]="getButtonIcon(button)"/></div>
    </ng-container>
</div>