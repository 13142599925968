// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  socketIOUrl: 'https://connected-experience.fordautoshows.com/client',
  ga: 'G-7PDDGLHWCC',
  storyblokAccessToken: 'iwwK76qDkJvdXDORMwhbvgtt',
  defaultStoryName: 'nyias-2024',
  gaMeasurementId: 'G-TNWKVTYRBK',
  cookiesId: '0c46631f-cdad-4228-92c9-a5b6aa7ccf0e-test',
};


// connected-experience.fordautoshows.com	A	Simple	-
// dualstack.connected-experience-alb-236433254.us-east-1.elb.amazonaws.com.
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
