import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
/**
 * @export
 * @class AnalyticsService
 */
export class AnalyticsService {
  /**
   * Send data to google analytics
   */
  sendPageView() {
    gtag('config', environment.gaMeasurementId);
  }

  /**
   * send button label and category as event
   * @param {*} eventCategory
   * @param {*} eventAction
   * @param {*} eventLabel
   */
  sendEvent(eventCategory, eventAction, eventLabel) {
    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
    });
  }
}
