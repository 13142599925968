<div class="question-frame">
    <div class="question-text" [innerHTML]="question.question | safe: 'html'"></div>
    <div class="timer">
        <app-stopwatch></app-stopwatch>
    </div>
</div>

<div class="image-answers-frame">
    <ng-container *ngFor="let answer of question.answers;">
        <div (click)="onAnswerSelected(answer)"
            class="btn"
            [style.background-image]="'url(' + answer.image.filename + ')'">    
            <div *ngIf="answer.label && answer?.label !== ''" class="label">
                {{answer.label}}
            </div>    
        </div>
    </ng-container>
</div>

<div class="footer">
    {{question.disclaimer}}
</div>