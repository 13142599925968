import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {QuizService} from 'src/app/services/quiz.service';
import {TimerService} from 'src/app/services/timer.service';
import {Answer, TextQuestion} from 'src/app/types/content';

@Component({
  selector: 'app-text-choice-quiz',
  templateUrl: './text-choice-quiz.component.html',
  styleUrls: ['./text-choice-quiz.component.scss', '../../quiz.component.scss'],
})
/**
 *
 * @export
 * @class TextChoiceQuizComponent
 * @implements {OnInit}
 */
export class TextChoiceQuizComponent {
  public question:TextQuestion;

  /**
   * Creates an instance of TextChoiceQuizComponent.
   * @param {ActivatedRoute} activatedRoute
   * @param {QuizService} quizService
   */
  constructor(activatedRoute: ActivatedRoute,
    private quizService: QuizService,
    private analyticsService: AnalyticsService,
    private timerService: TimerService) {
    this.question = activatedRoute.snapshot.data.content;
    this.timerService.start();
  }

  /**
   * @param {Answer} answer
   */
  public onAnswerSelected(answer: Answer) {
    this.quizService.answer(answer);
    this.analyticsService.sendEvent('Quiz Answer', 'Answer Selected', answer.label);
  }
}
